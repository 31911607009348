import { gql } from "@apollo/client"
import axios from "axios"
import { get } from "lodash"
import ls from "local-storage"

const resolvers = {
  Query: {
    cartDrawerVisible: (parent, _, { cache }) => {
      return false
    },

    rechargeCartDrawerVisible: (parent, _, { cache }) => {
      return false
    },

    loginDrawerVisible: (parent, _, { cache }) => {
      return false
    },

    registerDrawerVisible: (parent, _, { cache }) => {
      return false
    },

    forgotPasswordDrawerVisible: (parent, _, { cache }) => {
      return false
    },

    rechargeCheckout: async (parent, _, { cache }) => {
      const checkoutToken = ls("lastRechargeCheckoutID")

      if (checkoutToken) {
        let response = await axios.post(`/api/checkout/get`, {
          checkoutToken,
        })

        const checkout = get(response, "data.checkout")

        if (checkout?.completed_at === null) {
          return checkout
        }

        ls.remove("lastRechargeCheckoutID")

        return null
      }

      return null
    },
  },

  Mutation: {
    updateCartVisibilityFilter: (_, { cartDrawerVisible }, { cache }) => {
      const query = gql`
        query CartDrawerVisible {
          cartDrawerVisible @client
        }
      `
      const previous = cache.readQuery({ query })
      const data = {
        ...previous,
        cartDrawerVisible,
      }

      cache.writeQuery({ query, data })

      return cartDrawerVisible
    },

    updateRechargeCartVisibilityFilter: (
      _,
      { rechargeCartDrawerVisible },
      { cache }
    ) => {
      const query = gql`
        query RechargeCartDrawerVisible {
          rechargeCartDrawerVisible @client
        }
      `
      const previous = cache.readQuery({ query })
      const data = {
        ...previous,
        rechargeCartDrawerVisible,
      }

      cache.writeQuery({ query, data })

      return rechargeCartDrawerVisible
    },

    /**
     * show/hide login drawer
     */
    updateLoginDrawerVisibility: (_, { loginDrawerVisible }, { cache }) => {
      const query = gql`
        query LoginDrawerVisible {
          loginDrawerVisible @client
        }
      `
      const previous = cache.readQuery({ query })
      const data = {
        ...previous,
        loginDrawerVisible,
      }

      cache.writeQuery({ query, data })

      return loginDrawerVisible
    },

    /**
     * show/hide register drawer
     */
    updateRegisterDrawerVisibility: (
      _,
      { registerDrawerVisible },
      { cache }
    ) => {
      const query = gql`
        query RegisterDrawerVisible {
          registerDrawerVisible @client
        }
      `

      const previous = cache.readQuery({ query })
      const data = {
        ...previous,
        registerDrawerVisible,
      }

      cache.writeQuery({ query, data })

      return registerDrawerVisible
    },

    /**
     * show/hide forgot password drawer
     */
    updateForgotPasswordDrawerVisibility: (
      _,
      { forgotPasswordDrawerVisible },
      { cache }
    ) => {
      const query = gql`
        query ForgotPasswordDrawerVisible {
          forgotPasswordDrawerVisible @client
        }
      `

      const previous = cache.readQuery({ query })
      const data = {
        ...previous,
        forgotPasswordDrawerVisible,
      }

      cache.writeQuery({ query, data })

      return forgotPasswordDrawerVisible
    },

    updateRechargeCheckout: (_, { checkout }, { cache }) => {
      const query = gql`
        query rechargeCheckout {
          rechargeCheckout @client
        }
      `

      const previous = cache.readQuery({ query })
      const data = {
        ...previous,
        rechargeCheckout: checkout,
      }

      cache.writeQuery({ query, data })

      return checkout
    },
  },
}

export default resolvers
